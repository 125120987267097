import React from 'react';
import video from './img/video_pc.mp4'



const DesktopVideo = () => {



  return (

      <           video  src= {video} playsInline autoPlay  muted loop  className='h-full w-full object-cover'>
             </video>   
    
 
  );
};

export default DesktopVideo;
import React from "react";
import me from './img/me_2.webp';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';





const AboutMe = () => {

    const isMobile = useMediaQuery({ maxWidth: 1000 }); 

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (

        <div id='aboutMe' className="w-full h-screen flex flex-col items-center justify-center py-28 mt-5 "> 

        
            <div className="m-auto w-full h-full flex flex-col items-center justify-center   py-10 px-3  font-Crimson text-slate-500 bg-[conic-gradient(at_bottom_left,_var(--tw-gradient-stops))] from-slate-100 via-orange-300 to-rose-900">

                    
                    <h2 className='text-4xl font-bold mt-8 mb-16 px-5 text-center leading-tight'>Chi sono</h2>          
                 

                         {isMobile ? <>

                                        <Slider {...settings} className="max-w-[900px] text-white w-[95%] h-60 md:h-52 md:w-[80%] rounded-lg mb-10 bg-stone-400/80  shadow-md">
                                        
                                                <div  className="w-full h-60 md:h-52 px-1 flex justify-center items-center "> 
                                                    <div className="h-60 md:h-52 w-full flex flex-row  justify-center items-center  p-2 ">
                                                            
                                                            <div className="h-[200px] w-[280px] md:h-[200px] md:w-[180px]  rounded-full overflow-hidden flex items-start justify-center ">
                                                                    <img src={me} alt='me' className=' h-[260px] md:h-[260px] ' />
                                                            </div>
                                                            <p className="w-auto h-auto text-center p-1">
                                                                    Ciao, sono Naim Lopez e benvenuto nel mio progetto web                   
                                                            </p>
                                                        </div>
                                                </div>

                                                <div className="w-full h-60 md:h-52 px-1 "> 
                                                    <div className="w-full h-60 md:h-52 flex flex-col justify-center items-center  p-2 rounded-lg">
                                                            <p className="h-auto w-auto text-center">
                                                            Il progetto nasce con l'intenzione di condividere la conoscenza know-how appresa durante lo sviluppo di vari siti web per matrimoni.
                                                        
                                                            </p>
                                                        </div>
                                                </div>

                                                <div className="w-full h-60 md:h-52 px-1"> 
                                                    <div className="w-full h-60 md:h-52 flex flex-col justify-center items-center  p-2 rounded-lg">
                                                            <p className="h-auto w-auto text-center">
                                                                Questo sito vuole essere un punto di riferimento per le coppie che hanno deciso di sposarsi e stanno valutando un sito web per il proprio matrimonio. 
                                                        
                                                            </p>
                                                        </div>
                                                </div>

                                        </Slider>
                                </>
                        : <>
                                        <Slider {...settings} className="max-w-[900px] text-white w-[60%] h-full max-h-[400px] rounded-lg mb-10 bg-stone-400/80  shadow-md">
                                            
                                            <div  className="w-auto h-60 md:h-96 px-1 flex justify-center items-center "> 
                                                <div className="h-60 md:h-96 w-auto flex flex-row  justify-center items-center  gap-5 p-2 ">
                                                        
                                                        <div className="h-[200px] w-[220px] md:h-[300px] md:w-[200px]  rounded-full overflow-hidden flex items-start justify-center ">
                                                                <img src={me} alt='me' className=' h-[250px] md:h-[360px] ' />
                                                        </div>
                                                        <div className="w-96 h-auto text-center px-2 flex flex-col justify-center items-center">
                                                            <p className="my-2">
                                                                    Ciao, sono Naim Lopez e benvenuto nel mio progetto web
                                                            </p>

                                                        </div>

                                                    </div>
                                            </div>

                                            <div className="w-full h-60 md:h-96 px-1 "> 
                                                <div className="w-full h-60 md:h-96 flex flex-col justify-center items-center  p-2 rounded-lg">
                                                        <p className="h-auto w-auto text-center">
                                                        
                                                        Il progetto nasce con l'intenzione di condividere la conoscenza know-how appresa durante lo sviluppo di vari siti web per matrimoni.
                                                        </p>
                                                    </div>
                                            </div>

                                            <div className="w-full h-60 md:h-96 px-1"> 
                                                <div className="w-full h-60 md:h-96 flex flex-col justify-center items-center  p-2 rounded-lg">
                                                        <p className="h-auto w-auto text-center">
                                                        Questo sito vuole essere un punto di riferimento per le coppie che hanno deciso di sposarsi e stanno valutando un sito web per il proprio matrimonio. 
                                                    
                                                        </p>
                                                    </div>
                                            </div>

                                    </Slider>
                                
                                </>}
                           


                 



                        
                   
   
                 </div>
                </div>
    )
    }
    
    export default AboutMe
import {React} from 'react';
import Main from './components/Main'
import Preloading from './components/Preloading.jsx'
import CookieBanner01 from './components/CookieBanner01.jsx'
import AboutProject  from './components/AboutProject';
import WhySiteWeb from './components/WhySiteWeb';
import Navbar from './components/Navbar';
import AboutMe from './components/AboutMe';

function App() {


    

  return (
    <div className="App ">
      
          <CookieBanner01/>
          <Navbar/>
          <Preloading/>
          <Main/>
          <AboutProject/>
          <AboutMe/>
          <WhySiteWeb/> 

    </div>
  );
    }

export default App;

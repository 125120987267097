import React from "react";
import { useEffect, useRef, useState } from "react"; 
  
const RevealOnScroll = ({ children }) => { 
    const [isVisible, setIsVisible] = useState(false); 
    const ref = useRef(null); 
  
    useEffect(() => { 
        const onWindScroll = () => { 
            const element = ref.current; 
            if (element) { 
                const { top } = element.getBoundingClientRect(); 
                const isVisible = top < window.innerHeight; 
           setIsVisible(isVisible)   ;
             
            } 
        }; 
  
        window.addEventListener("scroll", onWindScroll); 
        return () => { 
            window.removeEventListener("scroll", onWindScroll); 
        }; 
    }, []); 
  
    const classes = ` abosolute delay-1000  transition-opacity ease-in
        ${isVisible ? "animate-slide-down" : "h-0 w-0 hidden"
        }`; 
  
    return ( 
        <div ref={ref} className={classes}> 
            {children} 
        </div> 
    ); 
}; 

const RevealOnScroll1 = ({ children }) => { 
    const [isVisible, setIsVisible] = useState(false); 
    const ref = useRef(null); 
  
    useEffect(() => { 
        const onWindScroll = () => { 
            const element = ref.current; 
            if (element) { 
                const { top } = element.getBoundingClientRect(); 
                const isVisible = top < window.innerHeight;
                setIsVisible(isVisible)   ;
            } 
        }; 
  
        window.addEventListener("scroll", onWindScroll); 
        return () => { 
            window.removeEventListener("scroll", onWindScroll); 
        }; 
    }, []); 
  
    const classes = `]  abosolute flex flex-col justify-center items-center
        ${isVisible ? "animate-slide-left" : "h-0 w-0 hidden"
        }`; 
  
    return ( 

       
        <div ref={ref} className={classes}> 
            {children} 
        </div> 
    ); 
}; 



const WhySiteWeb = () => {


    return (
            <div id = 'Perchè_sito_matrimoniale' className=" m-auto w-full h-full flex flex-col items-center justify-center overflow-y-auto">

            
                <div className='w-full max-w-[1400px]  py-8  font-Crimson text-slate-500  flex flex-col justify-center items-center '> 
                    <h2 className='w-full text-4xl font-bold my-8 px-5 text-center leading-tight'>Perché un Sito Web Matrimoniale?</h2>          
                    <img className='mt-10 object-cover w-full h-full lg:max-h-[600px] lg:max-w-[1200px] rounded-lg shadow-md' src ='https://images.pexels.com/photos/4126724/pexels-photo-4126724.jpeg' alt=""/>

                <div className="max-w-[1000px] text-md mt-6 pt-6  text-justify px-5">
                        <p className="mt-2">
                            So bene che, in prima approssimazione, l'idea di un sito web matrimoniale potrebbe sembrare un qualcosa di superfluo o addirittura esagerato per la corretta riuscita di un matrimonio.
                        </p>

                        <p className="my-2">
                            E in realtà non è propriamente come sembra, avrete sicuramente bisogno in ogni caso di comunicare e condividere informazioni di varia natura con i vostri ospiti.
                        </p>

                        <p className="mb-2">
                            La soluzione più semplice e banale che ho visto applicare finora è quella di far girare un messaggio su WhatsApp tra gli invitati, ma sono certo che nel ventunesimo secolo potremmo fare di meglio, soprattutto per quei matrimoni organizzi con scrupolo fin dal primo giorno.</p>
                        <p className="mb-2"> Un sito web ben realizzato vi consentirà d'interagire con i vostri ospiti, e ogni singolo dettaglio contenuto in esso sarà una testimonianza della vostra eleganza e dell'impegno eterno. In altre parole: una finestra nel vostro mondo, dove il fascino e l'eleganza si fondono con il vostro amore.</p>
                            
                        <p className=""> Di seguito trovate le potenzialità che può offrire di un sito web:</p>
                </div>


                    <div className='h-[1800px] lg:h-[1400px] w-full   items-center justify-center mt-2  p-6 '>


                        <RevealOnScroll1>
                            <div className=" w-full max-w-[860px] my-6  bg-[#A5D6C0] text-slate-500 px-4  py-10  flex flex-col items-center justify-center rounded-xl shadow-md">
                                <h1 className='w-full text-3xl font-bold text-left  mb-2'>Racconta la Tua Storia d'Amore</h1> 

                                <div className="w-full h-full relative overflow-y-hidden"> 
                                    <RevealOnScroll>
                                        <p className='text-md  px-2 text-justify font-bold font-Crimson'>Condividi la tua storia d'amore con amici e parenti in modo coinvolgente. Racconta come vi siete incontrati, come è cresciuto il vostro amore e come avete pianificato il grande giorno.</p>
                                    </RevealOnScroll>
                                </div>
                            </div>
                        </RevealOnScroll1>

                        <RevealOnScroll1>
                            <div className="h-full w-full max-w-[860px] my-6  bg-[#D6C0B8] text-slate-500  py-10 px-4  flex flex-col items-center justify-center rounded-xl shadow-md">
                                <h1 className='w-full text-3xl font-bold text-left mb-2'>Dettagli dell'Evento</h1>
                                <div className="w-full h-full relative overflow-y-hidden"> 
                                    <RevealOnScroll>
                                        <p className='text-md px-2 text-justify font-bold font-Crimson'>Fornisci ai tuoi ospiti tutti i dettagli importanti sul tuo matrimonio in un unico posto. Luogo, data, orario, e persino le istruzioni su come arrivarci saranno a portata di clic.</p>
                                    </RevealOnScroll>
                                </div>
                            </div>
                        </RevealOnScroll1>

  
                        <RevealOnScroll1>
                            <div className="h-full w-full max-w-[860px] my-6  bg-[#B8BCD6] text-slate-500  py-10 px-4  flex flex-col items-center justify-center rounded-xl shadow-md">
                                <h1 className='w-full text-3xl font-bold text-left mb-2'>Galleria Fotografica</h1>
                                <div className="w-full h-full relative overflow-y-hidden"> 
                                    <RevealOnScroll>
                                        <p className='text-md px-2 text-justify font-bold font-Crimson'>Carica foto dalla vostra storia insieme e dalle sessioni di foto di fidanzamento. I tuoi ospiti potranno rivivere i momenti più speciali che avete condiviso.</p>
                                    </RevealOnScroll>
                                </div>
                            </div>
                        </RevealOnScroll1>

                        <RevealOnScroll1>
                            <div className="h-full w-full max-w-[860px] my-6  bg-[#D6D5B8] text-slate-500  py-10 px-4  flex flex-col items-center justify-center rounded-xl shadow-md">
                                <h1 className='w-full text-3xl font-bold text-left mb-2'>RSVP Online</h1>
                                <div className="w-full h-full relative overflow-y-hidden"> 
                                    <RevealOnScroll>
                                        <p className='text-md px-2 text-justify font-bold font-Crimson'> RSVP è l'acronimo di "Répondez S'il Vous Plaît" e permette ai tuoi ospiti di rispondere online in modo rapido e senza sforzo. Inoltre, per una migliore organizzazione, potrai chiedere informazioni aggiuntive come ad esempio allergie alimentari, necessità di passaggio e varie ed eventuali.</p>
                                    </RevealOnScroll>
                                </div>
                            </div>
                        </RevealOnScroll1>

                        <RevealOnScroll1>
                            <div className="h-full w-full max-w-[860px] my-6  bg-[#D99B85] text-slate-500  py-10 px-4  flex flex-col items-center justify-center rounded-xl shadow-md">
                                <h1 className='w-full text-3xl font-bold text-left mb-2'>Lista di Regali</h1>
                                <div className="w-full h-full relative overflow-y-hidden"> 
                                    <RevealOnScroll>
                                        <p className='text-md px-2 text-justify font-bold font-Crimson'>Condividi la tua lista di regali in modo conveniente. Gli ospiti potranno scegliere il regalo perfetto per voi direttamente dal sito web.</p>
                                    </RevealOnScroll>
                                </div>
                            </div>
                        </RevealOnScroll1>

                        <RevealOnScroll1>
                            <div className="h-full w-full max-w-[860px] my-6  bg-[#D98085] text-slate-500  py-10 px-4  flex flex-col items-center justify-center rounded-xl shadow-md">
                                <h1 className='w-full text-3xl font-bold text-left mb-2'>Blog Matrimoniale</h1>
                                <div className="w-full h-full relative overflow-y-hidden"> 
                                    <RevealOnScroll>
                                        <p className='text-md px-2 text-justify font-bold font-Crimson'>Tieni aggiornati gli ospiti con i tuoi preparativi. Condividi dettagli sul tuo abito, sul menu, e sugli altri dettagli interessanti del tuo matrimonio.</p>
                                    </RevealOnScroll>
                                </div>
                            </div>
                        </RevealOnScroll1>

             
        
                    </div>

               

            
                </div>
            </div>
    )
    }
    
    export default WhySiteWeb
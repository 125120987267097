import {React,useState, useEffect} from 'react';
import { LiaCookieBiteSolid } from 'react-icons/lia'
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';

const CookieBanner01 = () => {

  const [showDetails, setShowDetails] = useState(false);
  
  const [cookieSettings, setCookieSettings] = useState({});


  const [cookieAnalytic, setCookieAnalytic] = useState(false);


  const [Banner, setBanner] = useState(false);



  useEffect(() => {
    // Controlla se l'utente ha già fornito il consenso ai cookie e carica le impostazioni
    const storedSettings = Cookies.get('matriSettings');
    if (storedSettings) {
      const listSettings = JSON.parse(storedSettings);
      setCookieSettings(listSettings);
      setCookieAnalytic(listSettings.analytics);

      //Apro e chiudo Banner
      setBanner(true);
    }else {
      document.body.classList.add('overflow-hidden');
    }
    
  }, []);

  useEffect(() => {
  if(cookieAnalytic){
    <Helmet>
    <title>Project Sposi</title>
    {/* Altri metatag o codice HTML da inserire nel tag head */}
    </Helmet>
    console.log("IFcookieAnalytic", cookieAnalytic )
  }
  console.log("cookieAnalytic", cookieAnalytic )
  }, [cookieAnalytic]);



  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };


  const handleTogglecookieAnalytic = () => {
    setCookieAnalytic(!cookieAnalytic);
  };




  const handleConsentSelected = () => {
    // Aggiorna le impostazioni dei cookie e salva nell'oggetto JSON
    const updatedSettings = {
      ...cookieSettings,
      analytics: cookieAnalytic, // Imposta su true se l'utente accetta i cookie di analisi
    };
    // Imposta il cookie con le impostazioni aggiornate
    Cookies.set('matriSettings', JSON.stringify(updatedSettings), { expires: 365 });
    // Aggiorna lo stato
    setCookieSettings(updatedSettings);
    //Apro e chiudo Banner
    setBanner(!Banner);
    if(Banner) {
      document.body.classList.add('overflow-hidden');
    }else
      {document.body.classList.remove('overflow-hidden');
    }
  };

  const handleConsentAcceptAll = () => {
    // Aggiorna le impostazioni dei cookie e salva nell'oggetto JSON
    setCookieAnalytic(true);
    const updatedSettings = {
      ...cookieSettings,
      analytics: cookieAnalytic, // Imposta su true se l'utente accetta i cookie di analisi
    };
    // Imposta il cookie con le impostazioni aggiornate
    Cookies.set('matriSettings', JSON.stringify(updatedSettings), { expires: 365 });
    // Aggiorna lo stato
    setCookieSettings(updatedSettings);
    //Apro e chiudo Banner
    setBanner(!Banner);
    if(Banner) {
      document.body.classList.add('overflow-hidden');
    }else
      {document.body.classList.remove('overflow-hidden');
    }
  };

  const handleConsentRefuseAll = () => {
    // Aggiorna le impostazioni dei cookie e salva nell'oggetto JSON
    setCookieAnalytic(false);
    const updatedSettings = {
      ...cookieSettings,
      analytics: cookieAnalytic, // Imposta su true se l'utente accetta i cookie di analisi
    };
    // Imposta il cookie con le impostazioni aggiornate
    Cookies.set('matriSettings', JSON.stringify(updatedSettings), { expires: 365 });
    // Aggiorna lo stato
    setCookieSettings(updatedSettings);
    //Apro e chiudo Banner
    setBanner(!Banner);
    if(Banner) {
      document.body.classList.add('overflow-hidden');
    }else
      {document.body.classList.remove('overflow-hidden');
    }
    
  };



  const handleToggleBanner = () => {
  //Apro e chiudo Banner
    setBanner(!Banner);
    if(Banner) {
      document.body.classList.add('overflow-hidden');
    }else
      {document.body.classList.remove('overflow-hidden');
    }
  };







useEffect( ()=> {
 
});



  return (

    
  <>

    {cookieAnalytic ? (      
      <Helmet>
        {/* Altri metatag o codice HTML da inserire nel tag head */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WPZ3QT815Z"></script>

        <script> 
        {`
           window.dataLayer = window.dataLayer || [];
           function gtag() {dataLayer.push(arguments);}
           gtag('js', new Date());
          gtag('config', 'G-WPZ3QT815Z');
          `}

        </script>
      </Helmet>)
      :
      (<> 
      
        </>)}


    {!Banner ? ( 

             

   <div className='h-full w-full absolute top-0 flex flex-col  items-center justify-start z-50 overflow-y-auto no-scrollbar'>

    {/* Finestra Banner */} 

      <div className={`fixed w-[95%] max-w-[600px]  top-[8%] lg:top-[180px] flex flex-col items-center z-50
                       overflow-y-auto no-scrollbar m-2 rounded-md bg-[#F08F67]/95 shadow-2xl pb-4
                       ${ showDetails ? 'ease-in duration-200 h-[90%]  max-h-[560px] ' : 'ease-out duration-200 h-full max-h-[350px] lg:max-h-[360px] '} `}>

         
            
            <h1 className='w-full text-white text-center font-bold font-mono text-xl p-3 mt-4'>Consenso Cookie</h1>
            
            <p className='w-full text-white mt-2 px-5 py-1 text-left '> Gentile visitatore, questo sito utilizza i cookie per una migliore esperienza di navigazione. Tramite questo pannello potrai gestire le tue preferenze in merito ai cookie.</p>
            
           
          
            <div className=' w-full h-auto flex flex-col justify-center items-center mt-3   '>
              <div className=' w-full  max-w-[270px] flex flex-row justify-between m-2 p-2  rounded-md '>  
                <button className='bg-[#278C61]/75 text-white font-bold py-1 px-3 rounded border-gray-900 border-2' onClick={handleConsentRefuseAll}> Rifiuta tutti</button>
                <button className='bg-[#278C61]/75 text-white font-bold py-1 px-3 rounded border-gray-900 border-2' onClick={handleConsentAcceptAll}> Accetta tutti</button>
              </div>
            </div>
   
            <div className='w-full h-auto flex items-center justify-center mt-4'>
                <button className='bg-[#278C61]/75 text-white font-bold py-1 px-3 rounded border-gray-900 border-2' onClick={handleToggleDetails}> Dettaglio coockie </button>
            </div>

{/* Finestra dettagli Cookie */} 
      <div className ={`w-full  max-w-[340px] z-50 flex  ${ showDetails ? 'ease-in duration-200 h-auto max-h-[300px]' : 'ease-out duration-200 [0px] min-h-[0px] '} `}>
          
      {!showDetails ? 

      (
        <></>  
      ) : 

      (
        <div className={`h-auto max-h-[300px] w-full flex flex-col p-2 items-center justify-center overflow-y-auto no-scrollbar overflow-hidden`} >
          
          <ul className='h-auto w-full  m-2 bg-orange-200/60'>

            <li  className='flex flex-col justify-center items-center'>
              
            <div className=' w-full h-auto grid grid-cols-10  border-2 border-slate-700 '>

                <div className='h-auto w-auto col-start-1 col-end-4  flex items-center justify-center '>
                    <p className='font-bold text-sm text-center p-1'>Necessario</p>
                </div>

                <div className=' flex flex-col col-start-4 col-end-5 justify-center items-center mx-2'>
                  <label for="check_1" className='bg-gray-100 relative w-20 h-10 rounded-full lg:cursor-pointer scale-50' >
                        <input type="checkbox" id="check_1" checked="true" className='sr-only ' />
                        <span className={`w-2/5 h-4/5  absolute rounded-full  left-1 top-1
                        transition-all duration-300 
                        bg-rose-300
                          `}></span>
                  </label>
                </div>
            
                <div className='p-2 bg-green-200/70 col-start-5 col-end-11 flex flex-col items-center justify-center ml-1' > <p className='text-xs text text-center'>Cookie per memorizzare la scelta utente in merito al Consenso Coookie.</p> </div>
                </div>
              {/* toggle switch */}
              <div className=' w-full h-auto grid grid-cols-10 border-2 border-slate-700 '>

                <div className='h-auto w-auto col-start-1 col-end-4  flex items-center justify-center '>
                    <p className='font-bold text-sm text-center p-1'>Analytic</p>
                </div>

                <div className=' flex flex-col col-start-4 col-end-5 justify-center items-center'>
                  <label for="check" className='bg-gray-100 relative w-20 h-10 rounded-full lg:cursor-pointer scale-50' >
                        <input type="checkbox" id="check" className='sr-only' checked={cookieAnalytic} onChange={handleTogglecookieAnalytic}/>

                        <span className={`w-2/5 h-4/5  absolute rounded-full  left-1 top-1
                        transition-all duration-300 
                        ${ cookieAnalytic ? 'bg-rose-600 left-11' : 'bg-rose-300 '}
                          `}></span>
                  </label>
                </div>

                <div className='p-2 bg-green-200/70 col-start-5 col-end-11 flex flex-col items-center justify-center ml-1' > <p className='text-xs text-center'>Cookie statistici aiutano a capire il traffico web di questo sito, raccogliendo e trasmettendo informazioni in forma anonima.</p> </div>

              </div>

            </li>
          </ul>

          <button className='bg-[#278C61]/75 text-white font-bold py-1 px-3 rounded border-gray-900 border-2 mt-2' onClick={handleConsentSelected}> Accetta selezionati </button>
      
        </div>
      )
      }

          </div>
      
      
      </div>




    </div> 



    ) :
    
    ( 
  
                <div className='h-auto w-auto fixed bottom-[2%] right-1 lg:right-[2%] flex flex-row gap-4 m-2 z-50 ease-in duration-100'>
                  <button onClick={handleToggleBanner}>
                  <LiaCookieBiteSolid className='bg-blue-300/40 rounded-full h-10 w-10' />
                  </button>
                </div>
     
    )}

  </>
 
  );
};

export default CookieBanner01;
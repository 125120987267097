import React from "react";


const AboutProject = () => {
    return (
     
                <div className=' max-w-[1400px]  m-auto px-5 md:grid md:grid-cols-2 md:grid-rows-1 gap-4 font-Crimson text-slate-500 overflow-y-hidden mt-14'> 
                    {/* Left Side*/}

                    {/* Left Side*/}
                    <div className='flex flex-col h-full justify-center md:hidden'>
                        <h3 className='w-full text-4xl md:text-6xl font-bold mb-4 text-center leading-tight'>Innanzitutto, occorre dire che il vostro matrimonio merita il suo sito web</h3>
                        <p className='text-2xl mt-4 font-bold text'>Condividi i dettagli del vostro matrimonio, immersi in un'atmosfera di classe e raffinatezza</p>
                        <p className='my-6 text-lg  text-justify font-Crimson text-slate-500'>Il giorno del tuo matrimonio è un evento unico nella vita, un momento speciale che meriterebbe di essere condiviso in modo straordinario.</p>
                    </div>

                    <div className=' h-full md:grid md:grid-cols-3 md:grid-rows-2 md:h-full lg:grid-cols-3 lg:grid-rows-1'>
                        <img className='row-span-2  col-span-2 object-cover w-full h-full p-2' src ='https://images.pexels.com/photos/3014856/pexels-photo-3014856.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt=""/>
                        <img className='hidden h-0 md:block row-span-1 object-cover w-full md:h-full p-2' src ='https://images.pexels.com/photos/14894653/pexels-photo-14894653.jpeg?auto=compress&cs=tinysrgb&w=1600' alt=""/>
                        <img className='hidden h-0 md:block row-span-1 object-cover w-full md:h-full p-2' src ='https://images.pexels.com/photos/14444207/pexels-photo-14444207.jpeg?auto=compress&cs=tinysrgb&w=1600' alt=""/>
                        <img className='hidden h-0 md:block row-span-1 object-cover w-full md:h-full  lg:hidden p-2' src ='https://images.pexels.com/photos/11977751/pexels-photo-11977751.jpeg?auto=compress&cs=tinysrgb&w=1600' alt=""/>
                        <img className='hidden h-0 md:block row-span-1 col-span-2 object-cover w-full md:h-full lg:hidden p-2' src ='https://images.pexels.com/photos/14493576/pexels-photo-14493576.jpeg?auto=compress&cs=tinysrgb&w=1600' alt=""/>                    
                    </div>

                    <div className='flex flex-col h-full justify-center md:hidden'>
                        <p className='mt-8 text-lg  text-justify font-Crimson text-slate-500'>Ai giorni d'oggi puoi creare e integrare al tradizionale invito cartaceo, un sito web matrimoniale personalizzato che catturi la bellezza e l'amore che condividi con il tuo partner.</p>
                    </div>
                    
                    {/* Right Side*/}
                    <div className='hidden h-0 flex-col justify-center md:flex  md:h-full row-span-1'>
                        <h3 className='text-5xl md:text-4xl font-bold leading-tight '>Innanzitutto, occorre dire che il vostro matrimonio merita il suo sito web</h3>
                        <p className='text-2xl py-6 font-bold'>Condividi i dettagli del vostro matrimonio, immersi in un'atmosfera di classe e raffinatezza</p>
                        <p className='pb-6  px-1 text-lg text-justify mt-6 pt-6 font-bold'>Il giorno del tuo matrimonio è un evento unico nella vita, un momento speciale che meriterebbe di essere condiviso in modo straordinario. Ai giorni d'oggi puoi creare e integrare al tradizionale invito cartaceo, un sito web matrimoniale personalizzato che catturi la bellezza e l'amore che condividi con il tuo partner.</p>
                    </div>

            
                </div>

    )
    }
    
    export default AboutProject
import React from 'react';
import video from './img/video_mobile.mp4'



const MobileVideo = () => {



  return (

            < video  src= {video} playsInline autoPlay  muted loop  className='h-screen w-full object-cover'>
             </video>   
    
 
  );
};

export default MobileVideo;
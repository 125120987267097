import React, {useState} from "react";
import {RiMenu5Line} from 'react-icons/ri';

const Navbar = () => {
    //Setting Menu Navbar opening on click
    const [nav, setNav] = useState(false)
    const handleNav = () => {
        setNav(!nav)
        if(!nav) {
            document.body.style.overflow='hidden'
        }else{
            document.body.style.overflow='auto'
        }

    }

    //Setting change nav color when scrolling
    const [color, setColor] = useState(false)
    const changeColor = () => {
        if (window.scrollY >= 10) {
            setColor(true)
        } else {
            //Imposto direttamente il setColor true in quanto con il parallex non funziona l'addEvent
            //setColor(false)
            setColor(false)
        }

    }

    window.addEventListener('scroll', changeColor)


    return (
        
            <div className={`top-0 h-12 fixed w-full flex justify-between py-4 font-Crimson bg-b px-2 items-center z-40 ${ color ? 'bg-gradient-to-r from-[#D0ACA8] from-60% via-[#B1C1B3] via-99% to-[#537074] to-3% border-b-2 shadow-2xl'  : ''} 
            ${ nav ? ''  : ''}`}>
                    
                    <div className={`${ nav ? 'h-screen w-screen bg-black/90 top-0 left-0 absolute z-0'  : ''}`} />
                     <h1 className='w-full text-white text-2xl z-20  mt-1 ml-1'>Project Sposi</h1>
               
                     
                     <div className="w-full" />
                   
                <RiMenu5Line onClick={handleNav} className='z-20 text-white cursor-pointer mr-1'  size={45}/>
                

                <div className={nav ? 'ease-in duration-200 fixed left-0 top-0 w-[70%] max-w-[200px] h-screen  bg-gradient-to-tr from-violet-300 to-[#537074]/40  flex justify-center items-center px-4 py-7 flex-col z-10 shadow-md shadow-slate-200 rounded-sm' : 'absolute top-0 h-screen left-[-100%] ease-in duration-300  z-10'}>
                
               
        
                <ul className={`flex flex-col  fixed w-full max-w-[100px] h-full items-center justify-center text-2xl tracking-wider text-white text-center  z-20 ${nav ? '':'ease-in  duration-300 left-[-100%]'}  `}>
                    
                    <a onClick={handleNav} href='#main'><li className="py-10">Home</li></a>
                    <a onClick={handleNav} href='#aboutMe'><li className="py-10"> Chi sono </li></a>
                    <a onClick={handleNav} href='#Perchè_sito_matrimoniale'> <li className="py-10 ">Perchè un sito web  matrimoniale?</li> </a>
                </ul>
                </div> 
                           
            </div>

    )
}

export default Navbar
import React, { useState, useEffect } from 'react';
import {  FaInstagram, FaEnvelope} from 'react-icons/fa'
import { useMediaQuery } from 'react-responsive';
import DesktopVideo from './DesktopVideo.jsx'
import MobileVideo from './MobileVideo.jsx'

const Main = () => {

    const isMobile = useMediaQuery({ maxWidth: 1000 }); // Imposta la larghezza massima per ritenere un dispositivo mobile
    const [showText, setShowText] = useState(false);
    const emailAddress = 'webapp.naim@gmail.com';
    const handleEmailClick = () => {
        const link = `mailto:${emailAddress}`;
        window.location.href = link;
      };

    useEffect(() => {
      setShowText(true);
    }, []);
    
    return (


        <div id='main' className='h-screen w-full'>
        
        {isMobile ? <MobileVideo/> : <DesktopVideo/>}
        
        

            <div className='w-full h-screen absolute top-0 left-0 flex flex-col items-center justify-center overflow-y-auto '>      
            <div className='w-full h-screen absolute top-0 left-0 bg-slate-700/30 z-10'/>     
                <div className='max-w-[1040px] w-full h-full  flex flex-col justify-start md:justify-center  items-center overflow-x-hidden tracking-wide'>
                    
                    <h1 className='text-4xl w-full font-bold text-white text-center mx-4 mt-20 sm:mt-[25%] lg:mt-10 py-8 z-20 border-y  bg-slate-100/10 font-Crimson px-2 italic '>Un sito speciale per un giorno speciale</h1>
                    
                    <h2 className={`transition-opacity ease-in duration-500  text-2xl delay-[2200ms]  text-white text-center mx-4 mt-20 pt-2 z-20 font-Crimson ${ showText ? 'opacity-100' : 'opacity-0'}`}> 
                    Tutte infomazioni di cui hai bisogno per realizzare il sito web del tuo matrimonio</h2>
                    

 
                    <div className='flex justify-between p-2 max-w-[100px] w-64 m-4 mt-[30%] z-20 text-gray-200 border-y-2'>
                        
                    <a   href={`mailto:${emailAddress}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        onClick={handleEmailClick}
                     > <FaEnvelope className='cursor-pointer' size={20}/> </a>
                        
                         
                        
                        <a href="https://www.instagram.com/naif92_naim/?hl=it"
                    target="_blank"
                    rel="noopener noreferrer"
                               
                      >  <FaInstagram className='cursor-pointer' size={20} /> </a>
                    
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Main

import React from "react";
import { useState, useEffect } from "react";
import { TypeAnimation } from 'react-type-animation';






function Preloading () {

        const [loading, setLoading] = useState(true);

        useEffect(() => {

  
                setTimeout(() => {
                   setLoading(false)
                  
                   console.log("heei", loading)
               }, 2000) 
            
             })
    
    return(
         
        <div className ={loading ? 'ease-in duration-200 fixed left-0 top-0 w-full h-screen bg-[#537282] px-4 py-7 flex flex-col items-center justify-center z-50': ' absolute flex flex-col items-center justify-start top-0 h-screen left-[-100%] ease-in duration-300 z-50'} >
            
                <div className="h-full w-full flex flex-col    items-center justify-center overflow-hidden text-white">
                        
                        <h1 className="absolute top-[25%] text-5xl font-Crimson border-b-2 p-1 text-center">Project Sposi</h1>    
                        
                        <h1 className=" m-2 mt-28 font-Mrssaint text-5xl tracking-wide text-center">
                        <TypeAnimation
                        sequence={[
                            'Naim Lopez',
                            2000, // Waits 1s
                        
                        ]}
                        wrapper="span"
                        cursor={false}
                        repeat={0}
                        speed={16}
                        style={{ fontSize: '1em', paddingLeft:'5px' }}
                    />
                     </h1>
                             
                </div>

           
           
        </div>

    

        );
};

export default  Preloading ;
